const appConfig = {
  "name": "onboarding-web",
  "version": "0.1.0",
  "stage": "prod",
  "deployThisBuild": false,
  "okta": {
    "issuer": "https://okta.mit.edu/oauth2/aus6sh93rjqnQuszg697",
    "clientId": "254pipcks698gpo2qocpt9gsoa",
    "redirectUris": [
      "https://onboarding-mr.atlas-apps.mit.edu"
    ],
    "postLogoutRedirectUris": [
      "https://onboarding-mr.atlas-apps.mit.edu/logout"
    ],
    "scopes": [
      "covid19/user",
      "digital-id/user",
      "onboarding/user",
      "openid",
      "profile",
      "user/admin.visiting-student",
      "user/all"
    ]
  },
  "amplify": {
    "userPoolId": "us-east-1_bCu6BU8d2",
    "userPoolWebClientId": "254pipcks698gpo2qocpt9gsoa",
    "oauth": {
      "domain": "atlas-auth.mit.edu",
      "scope": [
        "covid19/user",
        "digital-id/user",
        "onboarding/user",
        "openid",
        "profile",
        "user/admin.visiting-student",
        "user/all"
      ],
      "redirectUris": [
        "https://onboarding-mr.atlas-apps.mit.edu"
      ],
      "postLogoutRedirectUris": [
        "https://onboarding-mr.atlas-apps.mit.edu/logout"
      ],
      "responseType": "token",
      "options": {
        "AdvancedSecurityDataCollectionFlag": false
      }
    }
  }
};
export default appConfig;